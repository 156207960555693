<template lang="pug">
Loading(v-if="!isLoaded" )
ForwardSlots(
  v-else
  :key="calendarKey"
)
  component(
    :is="VDatePicker",
    v-if="range"
    v-bind="$attrs"
    ref="vdatepicker"
    v-model.range="innerModelRange"
    :locale="getLocaleLanguage"
    :min-date="minDate"
    :max-date="maxDate"
    :masks="masks"
    @transition-end="isRendered = true"
    @dayclick="handleDayClick"
  )
  component(
    :is="VDatePicker",
    v-else
    v-bind="$attrs"
    ref="vdatepicker"
    v-model="innerModelValue"
    :locale="getLocaleLanguage"
    :min-date="minDate"
    :max-date="maxDate"
    :masks="masks"
    @transition-end="isRendered = true"
    @dayclick="handleDayClick"
  )
</template>

<script>
import { mapState } from 'pinia'

import { DATEPICKER_DATE_FORMAT } from '@packages/date/formats'

/**
 * Custom implementation of v-calendar datepicker
 * https://vcalendar.io/datepicker
 *
 * Useful links:
 * - Disable specific days: https://vcalendar.io/calendar/dates.html#function-rules
 */
export default defineNuxtComponent({
  setup () {
    const VDatePicker = shallowRef(null)

    return {
      VDatePicker
    }
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      type: Date,
      default: undefined
    },

    range: {
      type: Object,
      default: undefined
    },

    isSelectingRange: {
      type: Boolean,
      default: false
    },

    selectRangeDays: {
      type: Number,
      default: null
    },

    minDate: {
      type: Date,
      default: new Date()
    },

    maxDate: {
      type: Date,
      default: null
    },

    masks: {
      type: Object,
      default: () => ({
        weekdays: 'WW'
      })
    },

    dateFormat: {
      type: String,
      default: DATEPICKER_DATE_FORMAT
    },

    /**
     * Allow end date to come before start date
     * @prop {boolean} allowReverseRange
     */
    allowEndBeforeStartRange: {
      type: Boolean,
      default: false
    },

    /**
     * Don't emit dayclick until full range
     * @prop {boolean} willEmitBeforeFullRange
     */
    preventDayClickEventBeforeCompleteRange: {
      type: Boolean,
      default: true
    }
  },

  emits: [
    'update:modelValue',
    'update:range',
    'update:isSelectingRange',
    'dayclick',
    'onDateRangeStart'
  ],
  expose: ['selectPeriod'],

  data () {
    return {
      innerModelValue: undefined,
      innerModelRange: undefined,
      calendarKey: 0,
      isLoaded: false,
      isRendered: false,
      preventSameDayClick: false
    }
  },

  computed: {
    ...mapState(useLocaleStore, ['getLocaleLanguage']),

    isRange () {
      return this.range?.dateRange
    },
  },

  watch: {
    innerModelValue (value) {
      const modelValueDate = this.$dayjs(this.modelValue.selectedDate, this.dateFormat)

      if (this.$dayjs(value).isSame(modelValueDate)) {
        return
      }

      this.$emit('update:modelValue', {
        dateRange: {},
        selectedDate: this.$dayjs(value).format(this.dateFormat),
        selectedDateRaw: value
      })
    },

    innerModelRange (value) {
      if (
        ['start', 'end'].every(edge => (
          !value[edge] ||
          (this.$dayjs(value[edge]).isSame(this.$dayjs(this.range.dateRange[edge], this.dateFormat)))
        ))
      ) {
        return
      }

      const startDate = this.$dayjs(value.start)
      const endDate = this.$dayjs(value.end)

      this.$emit('update:range', {
        dateRange: {
          startRaw: value.start,
          endRaw: value.end,

          start: startDate.isValid()
            ? startDate.format(this.dateFormat)
            : undefined,

          end: endDate.isValid() && (!this.allowEndBeforeStartRange || endDate.isAfter(startDate, 'day'))
            ? endDate.format(this.dateFormat)
            : undefined
        },

        selectedDate: startDate.format(this.dateFormat),
      })
    },

    modelValue () {
      this.syncModelValueWithInnerModelValue()
    },

    range () {
      this.syncModelRangeWithInnerModelRange()
    },

    selectRangeDays: {
      handler (value) {
        if (Number.isNaN(value) || !this.range?.dateRange?.start) {
          return
        }

        const startDate = this.$dayjs(this.range?.dateRange?.start, this.dateFormat)
        if (startDate.isValid()) {
          this.selectPeriod(startDate, value)
        }
      },

      immediate: true
    }
  },

  created () {
    this.syncModelValueWithInnerModelValue()
    this.syncModelRangeWithInnerModelRange()

    if (process.browser) {
      this.loadDatePicker()
    }
  },

  methods: {
    async loadDatePicker () {
      const [{ DatePicker }] = await Promise.all([
        import('v-calendar'),
        ...(process.browser ? [import('v-calendar/style.css')] : [])
      ])

      this.VDatePicker = DatePicker
      this.isLoaded = true
    },

    syncModelValueWithInnerModelValue () {
      if (this.isRange || !this.modelValue?.selectedDate) {
        return
      }

      this.innerModelValue = this.$dayjs(this.modelValue.selectedDate, this.dateFormat).toDate()
    },

    syncModelRangeWithInnerModelRange () {
      if (!this.isRange) {
        return
      }

      const { start, end } = this.range.dateRange

      const startDate = this.$dayjs(start, this.dateFormat)
      const endDate = this.$dayjs(end, this.dateFormat)

      this.innerModelRange = {
        start: startDate.isValid() ? startDate.toDate() : undefined,
        end: endDate.isValid() ? endDate.toDate() : undefined
      }
    },

    async handleDayClick (event) {
      if (this.isRange) {
        if (this.selectRangeDays) {
          this.innerModelRange = {
            start: event.date,
            end: this.$dayjs(event.date).add(this.selectRangeDays, 'days').toDate()
          }
          // To sync inner and outer state change before events
          await this.$nextTick()
        } else {
          const isSelecting = event.attributes.every(({ key }) => key !== 'select-drag')

          this.$emit('update:isSelectingRange', isSelecting)

          if (isSelecting) {
            this.$emit('onDateRangeStart', {
              ...event,
              dateRaw: event.date,
              date: this.$dayjs(event.date).format(this.dateFormat)
            })

            if (
              this.isRange &&
              this.preventDayClickEventBeforeCompleteRange &&
              !this.selectRangeDays
            ) {
              return
            }
          }
        }
      }

      //Check if date clicked is between start and end date
      if(!this.selectRangeDays && this.innerModelRange && this.innerModelRange.start && this.innerModelRange.end){
        if(this.$dayjs(event.date).isAfter(this.innerModelRange.start) && this.$dayjs(event.date).isBefore(this.innerModelRange.end)){
          this.preventSameDayClick = true
          this.innerModelRange = {
            start: event.date,
            end: undefined
          }
          return
        }

        // Check if start and end date is the same date
        if(this.$dayjs(this.innerModelRange.start).isSame(this.innerModelRange.end, 'day')){
          this.preventSameDayClick = true
          return
        }

        // Check if start date is the same as the clicked date
        if(!this.preventSameDayClick && this.$dayjs(this.innerModelRange.start).isSame(event.date, 'day')){
          this.innerModelRange = {
            start: event.date,
            end: undefined
          }
          return
        }
      }

      this.preventSameDayClick = false
      this.$emit('dayclick', {
        date: this.$dayjs(event.date).format(this.dateFormat),
        dateRaw: event.date
      })
    },

    /**
     * Select days from startDate
     * Can cause unexpected behaviour when used with selectDateRange
     * @param {string | Date} startDate
     * @param {number} days
     */
    selectPeriod (startDate, days = this.selectRangeDays) {
      const date = this.$dayjs(startDate)

      this.innerModelRange = {
        start: date.toDate(),
        end: date.add(days, 'days').toDate()
      }

      // Fix vcalendar bug not updating
      this.calendarKey++
    },
  }
})
</script>

<style lang="scss">
@import '@layers/web/assets/scss/modules/_calendar';
</style>
